<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{
              name: 'AutoawardList',
            }">自動派獎設定</b-breadcrumb-item>
            <b-breadcrumb-item :to="{
              name: 'AutoawardList',
            }">自動派獎列表</b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ isEdit ? " 編輯" : "新增" }}自動派獎 
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">
            {{ isEdit ? `編輯` : `新增` }}自動派獎
          </h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-10">
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="標題" label-for="title">
              <b-form-input id="title" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="state.form.title"
                :state="v$.form.title.$error ? false : null" :readonly="isView"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.title.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <b-form-row>
                <b-col class="col-md-6 col-sm-12">
                  <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="活動起日" label-for="start_at">
                    <datepicker
                      placeholder="Select Date"
                      :input-class="{
                       'form-control': true,
                       'is-invalid': v$.form.start_at_date.$error, 
                      }"
                      :language="zh"
                      format="yyyy-MM-dd"
                      bootstrap-styling
                      v-model="state.form.start_at_date"
                      :disabled="isView || isStarted"
                      :disabled-dates="disabledDates"
                    >
                    </datepicker>
                    <b-form-invalid-feedback :state="!v$.form.start_at_date.$error">活動起日為必填</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col class="col-md-6 col-sm-12">
                  <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="活動迄日" label-for="end_at">
                    <datepicker
                      placeholder="Select Date"
                      :input-class="{
                       'form-control': true,
                       'is-invalid': v$.form.end_at_date.$error, 
                      }"
                      format="yyyy-MM-dd"
                      :language="zh"
                      bootstrap-styling
                      v-model="state.form.end_at_date"
                      :disabled="isView"
                      :disabled-dates="disabledEndDates"
                    >
                    </datepicker>
                    <b-form-invalid-feedback :state="!v$.form.end_at_date.$error">活動迄日為必填</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <b-form-group class="col-12" >
              <b-form-radio-group v-model="state.awardOption.type" id="awardOption" class="pt-3">
                <b-form-radio value="url" class="form-inline mb-2 col-12" :disabled="isView">
                  <div class="form-group">
                    顧客下單後立即通知，發漸強導流連結
                    <b-form-input v-model="state.notificationOption.url" :state="v$.notificationOption.url.$error || v$.notificationOption.url.$invalid? false : null" class="mx-2" :disabled="state.awardOption.type != 'url'" :readonly="isView" style="width: 300px"></b-form-input>
                  </div>
                  <b-form-invalid-feedback :state="!v$.notificationOption.url.$error || !v$.notificationOption.url.$invalid">導流連結為必填，且為網址格式</b-form-invalid-feedback>
                </b-form-radio>
                <b-form-radio value="point" class="form-inline mb-2 col-12 flex-wrap" :disabled="isView">
                  顧客取貨後
                  <b-form-input v-model="state.notificationOption.after_days" class="mx-2" style="width: 5rem" :formatter="maxLength(5)" :state="v$.notificationOption.after_days.$error && state.awardOption.type == 'point' ? false : null" :disabled="state.awardOption.type != 'point'" :readonly="isView"></b-form-input>
                  日發通知，通知後
                  <b-form-input v-model="plusDay" class="mx-2" style="width: 5rem" :formatter="maxLength(5)" :state="v$.awardOption.after_days.$error && state.awardOption.type == 'point'? false : null" :disabled="state.awardOption.type != 'point'" :readonly="isView"></b-form-input>
                  日發POYA點數
                  <b-form-input v-model="state.awardOption.point" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.awardOption.point.$error && state.awardOption.type == 'point'? false : null" :disabled="state.awardOption.type != 'point'" :readonly="isView"></b-form-input>
                  點。
                  <div style="width: 100%"></div>
                  （單筆需滿
                  <b-form-input v-model="state.requirementOption.amount" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.requirementOption.amount.$error && state.awardOption.type == 'point'? false : null" :disabled="state.awardOption.type != 'point'" :readonly="isView"></b-form-input>
                  元符合資格，可累贈，活動期間每位會員上限
                  <b-form-input v-model="state.limitOption.value" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.limitOption.value.$error && state.awardOption.type == 'point'? false : null" :disabled="state.awardOption.type != 'point'" :readonly="isView"></b-form-input>
                  點）
                  <b-form-invalid-feedback :state="v$.notificationOption.after_days.$error && state.awardOption.type == 'point' ? false : null">取貨後天數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.awardOption.after_days.$error && state.awardOption.type == 'point' ? false : null">贈禮天數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.awardOption.point.$error && state.awardOption.type == 'point' ? false : null">點數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.requirementOption.amount.$error && state.awardOption.type == 'point' ? false : null">單筆累贈必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.limitOption.value.$error && state.awardOption.type == 'point' ? false : null">上限必填，且為數字</b-form-invalid-feedback>
                </b-form-radio>
                <b-form-radio value="multiplier" class="form-inline mb-2 col-12 flex-wrap" :disabled="isView">
                  顧客取貨後
                  <b-form-input v-model="state.notificationOption.after_days" class="mx-2" style="width: 5rem" :formatter="maxLength(5)" :state="v$.notificationOption.after_days.$error && state.awardOption.type == 'multiplier' ? false : null" :disabled="state.awardOption.type != 'multiplier'" :readonly="isView"></b-form-input>
                  日發通知，通知後
                  <b-form-input v-model="plusDay" class="mx-2" style="width: 5rem" :formatter="maxLength(5)" :state="v$.awardOption.after_days.$error && state.awardOption.type == 'multiplier' ? false : null" :disabled="state.awardOption.type != 'multiplier'" :readonly="isView"></b-form-input>
                  日發POYA點數
                  <b-form-input v-model="state.awardOption.multiplier" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.awardOption.multiplier.$error && state.awardOption.type == 'multiplier' ? false : null" :disabled="state.awardOption.type != 'multiplier'" :readonly="isView"></b-form-input>
                  倍。
                  <div style="width: 100%"></div>
                  （單筆需滿
                  <b-form-input v-model="state.requirementOption.amount" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.requirementOption.amount.$error && state.awardOption.type == 'multiplier' ? false : null" :disabled="state.awardOption.type != 'multiplier'" :readonly="isView"></b-form-input>
                  元符合資格，不可累贈，活動期間每位會員上限
                  <b-form-input v-model="state.limitOption.value" class="mx-2" style="width: 8rem" :formatter="maxLength(8)" :state="v$.limitOption.value.$error && state.awardOption.type == 'multiplier' ? false : null" :disabled="state.awardOption.type != 'multiplier'" :readonly="isView"></b-form-input>
                  點）
                  <b-form-invalid-feedback :state="v$.notificationOption.after_days.$error && state.awardOption.type == 'multiplier' ? false : null">取貨後天數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.awardOption.after_days.$error && state.awardOption.type == 'multiplier' ? false : null">贈禮天數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.awardOption.multiplier.$error && state.awardOption.type == 'multiplier' ? false : null">倍數為必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.requirementOption.amount.$error && state.awardOption.type == 'multiplier' ? false : null">單筆累贈必填，且為數字</b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="v$.limitOption.value.$error && state.awardOption.type == 'multiplier' ? false : null">上限必填，且為數字</b-form-invalid-feedback>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>

          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">取消</b-button>
        <b-overlay :show="isSubmmiting" rounded opacity="0.6" spinner-small spinner-variant="primary" 
          class="d-inline-block">
          <b-button variant="success" @click="handleSubmit" v-if="!isView">
            {{ isEdit ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<style scoped>
::v-deep .custom-control-label {
  justify-content: start;
  flex-wrap: wrap;
}
</style>

<script>
import { reactive} from "@vue/composition-api";
import { mapState } from "vuex";
import { zh } from "vuejs-datepicker/dist/locale";
import subDays from "date-fns/subDays";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf, numeric} from "@vuelidate/validators";
import autoawardApi from "../../../apis/autoaward";
import orgNameMixin from "@/mixins/organizationName";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import _ from "lodash";


export default {
  components: {Datepicker},
  mixins: [orgNameMixin],
  setup() {
    const state = reactive({
      form: {
        title: "",
        start_at: null,
        end_at: null,
        start_at_date: moment().format('YYYY-MM-DD 00:00:00'),
        end_at_date: moment().format('YYYY-MM-DD 23:59:59'),
        start_at_time: '00:00',
        end_at_time: '23:59:59',
        config: {
          is_accm: false,
          requirements: [],
          notifies: [],
          awards:[],
          limits: [],
        },
      },
      awardOption: {
        type: null,
        point: 0,
        multiplier: 0,
        after_days: 0,
        after: 0
      },
      notificationOption: {
        after: 'created_at',
        after_days: 0,
        url: null,
      },
      limitOption: {
        value: 0,
      },
      requirementOption: {
        amount: 0,
      }
    });

    const rules =  {
      form: {
        title: { required },
        start_at_date: { required },
        start_at_time: { required },
        end_at_date: { required },
        end_at_time: { required },
      },
      notificationOption: {
        type: {},
        after_days: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'point' || this.state.awardOption.type == 'multiplier'
          }),
        },
        url: {
          required: requiredIf(function () {
            return this.state.awardOption.type == 'url'
          }),
        }
      },
      limitOption: {
        value: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'point' || this.state.awardOption.type == 'multiplier'
          }),
        },
      },
      requirementOption: {
        amount: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'url'
          }),
        },
      },
      awardOption: {
        type: {
        },
        after: {},
        after_days: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'point' || this.state.awardOption.type == 'multiplier'
          }),
        },
        multiplier: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'multiplier'
          }),
        },
        point: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == 'point' 
          }),
        },
      }
    }

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isStarted: false, 
      disabledDates: { to: subDays(new Date(), 1) },
      zh,
      isLoading: false,
      isSubmmiting: false,
      form: {
        title: "",
        start_at: undefined,
        end_at: undefined,
        config: [],
      },
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledEndDates() {
      // 活動開始後只能選今日以後，不然就只能選起日之後
      return isBefore(this.state.form.start_at_date, new Date()) ?
        { to: subDays(new Date(), 1) } :
        { to: new Date(this.state.form.start_at_date)}
    },
    plusDay: {
      get() {
        return (this.state.awardOption.after_days || 0) - (this.state.notificationOption.after_days || 0) 
      },
      set(value) {
        this.state.awardOption.after_days = Math.max((+value || 0) + (+this.state.notificationOption.after_days || 0), 0)
      }
    },
    autoawardId() {
      return this.$route.params.autoawardId;
    },
    merchantId() {
      return this.$route.query.merchantId;
    },
    isEdit() {
      return this.$route.name === "AutoawardEdit";
    },
    isView() {
      return this.$route.name === "AutoawardView";
    },
  },
  mounted() {
    if (
      !this.checkPermission([consts.POYA_AUTOAWARD_MODIFY]) ||
      !this.checkPermission([consts.POYA_AUTOAWARD_CREATE])
    ) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    } else if ((!this.isEdit && !this.isView) && !this.merchantId) {
      this.$swal
        .fire({
          type: "error",
          text: "未指定通路",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    } else if (this.autoawardId) {
      this.fetchAutoaward();
    }
  },
  methods: {
    maxLength(length) {
      return v => {
          return String(v).substring(0, length);
      }
    },
    isBeforeNow(date) {
      return isBefore(new Date(date), new Date())
    },
    isAfterNow(date) {
      return isAfter(new Date(date), new Date())
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    unwrapConfig(config) {
      return  {
        notifies: config.notifies.pop() || {},
        requirements: config.requirements.pop() || {},
        awards: config.awards.pop() || {},
        limits: config.limits.pop() || {},
      }
    },
    async prepareConfig() {
      // 這邊 Paddy 覺得以後會有多選項，所以很多都是用 array 儲存
      let {notifies, requirements, awards, limits} = this.unwrapConfig(this.state.form.config)

      switch (this.state.awardOption.type) {
        case 'url':
          notifies = {
            type: "waltily",
            template_code: "groupbuying_award_link",
            after: "created_at",
            after_days: 0,
            url: this.state.notificationOption.url,
          }
          awards = {}
          limits = {}
          requirements = {}
          break
        case 'point':
          notifies = {
            type: "waltily",
            template_code: "groupbuying_award_point",
            after: "pickup_at",
            after_days: parseInt(this.state.notificationOption.after_days)
          }
          awards = {
            type: "point",
            point: parseInt(this.state.awardOption.point),
            after_days: parseInt(this.state.awardOption.after_days),
            after: "pickup_at",
          }
          limits = {
            type: 'each_customer_max_points',
            value: parseInt(this.state.limitOption.value)
          }
          requirements = {
            type: 'order_amount' ,
            amount: parseInt(this.state.requirementOption.amount)
          }

          this.state.form.config.is_accm = true
          break
        case 'multiplier':
          notifies = {
            type: "waltily",
            template_code: "groupbuying_award_point",
            after: "pickup_at",
            after_days: parseInt(this.state.notificationOption.after_days)
          }
          awards = {
            type: "multiplier",
            multiplier: parseInt(this.state.awardOption.multiplier),
            after_days: parseInt(this.state.awardOption.after_days),
            after: "pickup_at",
          }
          limits = {
            type: 'each_customer_max_points',
            value: parseInt(this.state.limitOption.value)
          }
          requirements = {
            type: 'order_amount' ,
            amount: parseInt(this.state.requirementOption.amount)
          }

          this.state.form.config.is_accm =false 
      }

      for (const [key, value] of Object.entries({notifies, requirements, awards, limits})) {
        if (!_.isEmpty(value)) {
          this.state.form.config[key].push(value)
        }
      }
    },
    restoreConfigOption(config) {
      let {notifies, requirements, awards, limits} = this.unwrapConfig(config)

      awards.type = awards.type || 'url' 

      console.log(awards.type)
      this.state.notificationOption = {
        ...notifies,
      }
      this.state.awardOption = {
        ...awards
      }
      this.state.limitOption = {
        ...limits
      }
      this.state.requirementOption = {
        ...requirements
      }
      this.state.form.config.is_accm = config.is_accm

    },
    deleteConfig(i) {
      this.$delete(this.state.form.config, i)
    },
    clearAwardOption() {
      this.state.awardOption = {
        type: null,
        day: null,
        template_id: null,
        point: null,
        multiplier: null,
      }
    },
    async fetchAutoaward() {
      try {
        this.isLoading = true;
        const { data } = await autoawardApi.getAutoaward(this.autoawardId);
        const { title, config, start_at, end_at } =
          data.data;

        if (this.isEdit || this.isView) {
          this.form = {
            ...this.form,
          };
          this.state.form = {
            title,
            config,
            start_at_date: moment(start_at).isValid()
              ? moment(start_at).format("YYYY-MM-DD")
              : undefined,
            start_at_time: moment(start_at).isValid()
              ? moment(start_at).format("HH:mm")
              : '00:00',
            end_at_date: moment(end_at).isValid()
              ? moment(end_at).format("YYYY-MM-DD")
              : undefined,
            end_at_time: moment(end_at).isValid()
              ? moment(end_at).format("HH:mm")
              : '00:00',
          }
          this.isStarted = this.isEdit && isBefore(new Date(start_at), new Date())
          this.restoreConfigOption(config)
        }
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      this.prepareConfig()
      this.state.form.start_at = moment(this.state.form.start_at_date).format("YYYY-MM-DD 00:00:00")
      this.state.form.end_at = moment(this.state.form.end_at_date).format("YYYY-MM-DD 23:59:59")
      const result = await this.v$.$validate();
      console.log(this.v$)
      if (!result) return;

      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }

    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        await autoawardApi.updateAutoaward(
          this.autoawardId,
          this.omitNullDate({
            ...this.form,
            ...this.state.form,
          })
        );
        this.showSuccessPopup();
      } catch (error) {
        console.error(error)
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        await autoawardApi.createAutoaward(
          this.merchantId,
          this.omitNullDate({
            ...this.form,
            ...this.state.form,
          })
        );
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "AutoawardList" });
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    omitNullDate(form) {
      const result = { ...form };

      if (!result.start_at) {
        delete result.start_at;
      }
      if (!result.end_at) {
        delete result.end_at;
      }

      return result;
    },
  },
};
</script>
